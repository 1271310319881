import React, { Component } from "react";
import AppLogo from "./AppLogo";
import GitHubButton from "react-github-btn";

class AppBar extends Component {
  render() {
    return (
      <div
        className="w-100 d-flex justify-content-between align-items-center px-4"
        style={{ height: "49px" }}
      >
        <AppLogo />
        <div className="ml-5 d-flex justify-content-center align-items-center display-none-sm-down">
          <a className="text-dark no-underline mr-2 text-lg" href="/#features">
            Features
          </a>&nbsp;|&nbsp;&nbsp;&nbsp;
          <a
            className="no-underline mr-2 text-dark text-lg"
            href="https://docs.traduora.co"
            rel="noopener"
          >
            Documentation
          </a>
        </div>
        <div className="flex-1" />
        <div className="d-flex justify-content-center align-items-center">
          <GitHubButton
            href="https://github.com/ever-co/ever-traduora"
            data-size="large"
            data-show-count="true"
            aria-label="Star ever-co/ever-traduora on GitHub"
          >
            GitHub
          </GitHubButton>
        </div>
      </div>
    );
  }
}

export default AppBar;
