import React from "react";

const TopDivider = () => (
  <svg
    viewBox="0 0 1200 53"
    preserveAspectRatio="none"
    className="text-white bg-accent-light"
    style={{
      height: "2rem",
      width: "100%",
      position: "absolute",
      top: "-2rem"
    }}
  >
    <path
      fill="currentColor"
      d="M1196.008 53H1200V0H0v44.816-8.184C159.341 14.63 311.343 2.484 456.007.196 600.122-2.084 846.789 15.518 1196.008 53z"
    />
  </svg>
);

const BottomDivider = () => (
  <svg
    viewBox="0 0 1200 46"
    preserveAspectRatio="none"
    className="text-white bg-accent-light"
    style={{
      height: "2rem",
      width: "100%",
      position: "absolute",
      bottom: "-2rem"
    }}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0-7h1200v53H0V-7zm0 30.113V-7h1200v43.495c-37.762 7.58-155.36 7.58-352.791 0C721.412 31.665 480.68.297 248.535.355 197.087.368 114.242 7.955 0 23.113z"
    />
  </svg>
);

const FeatureHighlight = () => {
  return (
    <div id="overview" className="w-100 position-relative my-5">
      <TopDivider />
      <div className="py-5 bg-accent-light">
        <div className="container">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="flex-1 d-flex flex-column justify-content-center text-center m-5">
              <h2 className="font-serif text-dark">
                A modern translation workflow
              </h2>
              <p className="text-dark">
                Import and export the most common formats, work together with
                your team, instantly deliver translation updates over the air,
                and more! Traduora is the perfect home for managing your
                translation workflow.
              </p>
            </div>
            <div
              style={{
                flex: 2
              }}
            >
              <img
                className="image"
                src={require("../assets/traduora-preview.png").default}
                alt="Features"
              />
            </div>
          </div>
        </div>
      </div>
      <BottomDivider />
    </div>
  );
};

export default FeatureHighlight;
