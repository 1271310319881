import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import Route from "react-router-dom/Route";
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import MainPage from "./components/MainPage";
import NotFound from "./components/NotFound";
import ScrollToTop from "./components/ScrollToTop";

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <div className="d-flex flex-column w-100 min-h-screen">
            <div className="container">
              <AppBar />
            </div>            
            <Switch>
              <Route path="/" exact component={MainPage} />
              <Route path="/404" exact component={NotFound} />
              <Redirect to="/404" />
            </Switch>
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
