import React from "react";

const Footer = () => {
  return (
    <div className="container">
      <footer className="pt-4 my-md-5 pt-md-5">
        <div className="row">
          <div className="col-4 col-md">
            <h5>Product</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted no-underline" href="/#overview">
                  Overview
                </a>
              </li>
              <li>
                <a className="text-muted no-underline" href="/#features">
                  Features
                </a>
              </li>
            </ul>
          </div>
          <div className="col-4 col-md">
            <h5>Community</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a
                  className="text-muted no-underline"
                  href="https://docs.traduora.co"
                  rel="noopener"
                >
                  Documentation
                </a>
              </li>
              <li>
                <a
                  className="text-muted no-underline"
                  href="https://hub.docker.com/r/traduora/traduora"
                  rel="noopener"
                >
                  Docker Hub
                </a>
              </li>
              <li>
                <a
                  className="text-muted no-underline"
                  href="https://github.com/ever-co/ever-traduora"
                  rel="noopener"
                >
                  GitHub
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
