import React from "react";
import FeatureHighlight from "./FeatureHighlight";
import Features from "./Features";
import Header from "./Header";
import Usage from "./Usage";

const MainPage = () => (
  <div>
    <Header />
    <FeatureHighlight />
    <Features />
    <Usage />
  </div>
);

export default MainPage;
