import React from "react";

const TopDivider = () => (
  <svg
    viewBox="0 0 1200 53"
    preserveAspectRatio="none"
    className="text-white bg-accent-light"
    style={{
      height: "2rem",
      width: "100%",
      position: "absolute",
      top: "-2rem",
    }}
  >
    <path
      fill="currentColor"
      d="M1196.008 53H1200V0H0v44.816-8.184C159.341 14.63 311.343 2.484 456.007.196 600.122-2.084 846.789 15.518 1196.008 53z"
    />
  </svg>
);

const BottomDivider = () => (
  <svg
    viewBox="0 0 1200 46"
    preserveAspectRatio="none"
    className="text-white bg-accent-light"
    style={{
      height: "2rem",
      width: "100%",
      position: "absolute",
      bottom: "-2rem",
    }}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0-7h1200v53H0V-7zm0 30.113V-7h1200v43.495c-37.762 7.58-155.36 7.58-352.791 0C721.412 31.665 480.68.297 248.535.355 197.087.368 114.242 7.955 0 23.113z"
    />
  </svg>
);

const Usage = () => {
  return (
    <div id="usage" className="w-100 position-relative my-5">
      <TopDivider />
        <div className="py-5 bg-accent-light">
          <div className="container mt-3 text-center">
            <div className="row">
              <div
                className="col my-3"
                style={{
                  maxWidth: "960px",
                  margin: "auto",
                }}
              >
                <h2>You're in good company</h2>
                <p>Join thousands of developers around the world who use traduora.</p>
                <p>
                  With more than half a million downloads and 1500+ stars on GitHub,
                  we want Traduora to become the home for managing your translation workflow.
                  That's why we have made all of the core product open-source with the intention
                  to grow a community and enable developers to build on top of it as a platform.
                </p>
                <p>
                  Traduora is <strong>open source</strong> and will always be free.
                </p>
                <a
                  className="my-3 btn btn-primary"
                  href="https://github.com/ever-co/ever-traduora"
                  rel="noopener"
                  style={{ maxWidth: "400px" }}
                >
                  Get started
                </a>
              </div>
            </div>
          </div>
        <BottomDivider />
      </div>
    </div>
  );
};

export default Usage;
