import React from "react";

const Features = () => {
  return (
    <div id="features" className="container d-flex flex-column">
      {/* Editing Feature */}
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center m-3">
        <div className="flex-1 d-flex flex-column justify-content-center text-center">
          <h3 className="font-serif text-dark">Get up and running fast</h3>
          <p className="text-dark">
            Setup your project in seconds, find what you're looking for with
            instant search, edit your translations and seamlessly deliver them
            with over the air updates. Traduora has been carefully designed for
            ease of use and productivity.
          </p>
        </div>
        <div className="flex-1 p-5">
          <img
            className="image"
            src={require("../assets/search.jpg").default}
            alt="Translation Editing Feature"
          />
        </div>
      </div>
      {/* Teams */}
      <div className="d-flex flex-column flex-md-row flex-md-row-reverse justify-content-center align-items-center m-3">
        <div className="flex-1 d-flex flex-column justify-content-center text-center">
          <h3 className="font-serif text-dark">Collaborate on your project</h3>
          <p className="text-dark">
            Invite your team, assign roles and permissions - everyone can work
            together on the same project.
          </p>
        </div>
        <div className="flex-1 p-5">
          <img
            className="image"
            src={require("../assets/team.jpg").default}
            alt="Team collaboration"
          />
        </div>
      </div>
      {/* API access */}
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center m-3">
        <div className="flex-1 d-flex flex-column justify-content-center text-center">
          <h3 className="font-serif text-dark">Automate everything</h3>
          <p className="text-dark">
            Integrate Traduora into your workflow via our secure API. Control
            who has access to your project's data via API keys. Web and Mobile
            SDKs coming soon for the most popular frameworks.
          </p>
        </div>
        <div className="flex-1 p-5">
          <img
            className="image"
            src={require("../assets/connect.jpg").default}
            alt="API access"
          />
        </div>
      </div>
      {/* Formats */}
      <div className="d-flex flex-column flex-md-row flex-md-row-reverse justify-content-center align-items-center m-3">
        <div className="flex-1 d-flex flex-column justify-content-center text-center">
          <h3 className="font-serif text-dark">
            Your favorite formats are here
          </h3>
          <p className="text-dark">
            We currently support JSON flat and nested, CSV, YAML flat and
            nested, Java Properties, XLIFF 1.2, Gettext (po), Apple Strings,
            Android Resources (xml) and more coming soon! The roadmap includes:
            PHP arrays and Microsoft Resources.
            <br />
            <a
              className="mt-3 btn btn-primary"
              href="https://docs.traduora.co/docs/concepts/formats"
              rel="noopener"
            >
              Formats Documentation
            </a>
          </p>
        </div>
        <div className="flex-1 p-5">
          <img
            className="image"
            src={require("../assets/formats.jpg").default}
            alt="Supported formats"
          />
        </div>
      </div>
      {/* Open-Source */}
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center m-3">
        <div className="flex-1 d-flex flex-column justify-content-center text-center">
          <h3 className="font-serif text-dark">Open source</h3>
          <p className="text-dark">
            Traduora is built and maintained as an open source project, so the
            community can help improve it too. You can help build new features, interface
            improvements, and everyone can benefit from it.
            <br />
            <a
              className="mt-3 btn btn-primary"
              href="https://github.com/ever-co/ever-traduora"
              rel="noopener"
            >
              Traduora on GitHub
            </a>
          </p>
        </div>
        <div className="flex-1">
          <img
            className="image p-5"
            src={require("../assets/github.png").default}
            alt="GitHub logo"
          />
        </div>
      </div>
    </div>
  );
};

export default Features;
