import React from "react";
import { Link } from "react-router-dom";

const NotFound = (props) => {
  return (
    <div
      id="header"
      className="container"
      style={{
        maxHeight: "700px",
      }}
    >
      <div
        className="row justify-content-center align-items-center w-100 h-100"
        style={{ minHeight: "400px" }}
      >
        <div className="col text-center">
          <h1 className="font-serif font-weight-bold">Not found</h1>
          <p className="text-muted">
            The page that you are looking for was not found.
          </p>
          <Link to={"/"}>Go back</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
