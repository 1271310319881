import React from "react";

const Header = () => {
  return (
    <div
      id="header"
      className="container d-flex"
      style={{
        maxHeight: "700px"
      }}
    >
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center my-md-5">
        <div className="flex-1">
          <img
            className="lead-image m-0"
            src={require("../assets/main.jpg").default}
            alt="Header"
          />
        </div>
        <div className="flex-1 d-flex flex-column justify-content-center text-center">
          <h1 className="font-serif text-left">
            <strong>Open Translation Management Platform</strong>
          </h1>
          <h5 className="text-dark text-left">
            Teams use Ever Traduora to reach users all around the globe. Automate
            your translation workflow today.
            <br />
            <div className="d-flex justify-content-start align-items-center mt-3">
              <a
                href="https://github.com/ever-co/ever-traduora"
                rel="noopener noreferrer"
                target="_blank"
                className="btn btn-primary text-white mr-3"
                style={{ width: "140px" }}
              >
                Get started
              </a>
             
            </div>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Header;
